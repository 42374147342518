import axios from 'axios'

export default {
  state: {
    loggedInUser:
      localStorage.getItem("userInfo") != null
        ? JSON.parse(localStorage.getItem("userInfo"))
        : null,
    accessToken: localStorage.getItem("accessToken"),
    loading: false,
    signUpSuccess: false,
    error: null
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    accessToken: state => state.accessToken,
    loading: state => state.loading,
    signUpSuccess: state => state.signUpSuccess,
    error: state => state.error
  },
  mutations: {
    setUser(state, data) {
      state.loggedInUser = data;
      state.loading = false;
      state.error = null;
    },
    setAccessToken(state, data) {
      state.accessToken = data;
    },
    setSignupSuccess(state, data) {
      state.signUpSuccess = data;
      state.loading = false;
    },
    setLogout(state) {
      state.loggedInUser = null;
      state.loading = false;
      state.error = null;
      // this.$router.go("/");
    },
    setLoading(state, data) {
      state.loading = data;
    },
    setError(state, data) {
      state.error = data;
      state.loggedInUser = null;
      state.loading = false;
    },
    clearError(state) {
      state.error = null;
    }
  },
  actions: {
    toggleLoading ({ commit }, status) {
      commit("setLoading", status)
    },
    login({ commit }, data) {
      commit("clearError");
      commit("setLoading", true);
      axios.post('http://212.71.245.25:9191/api/auth/signin', data)
        .then((response) => {
          if (response.data.success) {
            localStorage.setItem("userInfo", JSON.stringify(response.data.summary))
            localStorage.setItem("accessToken", response.data.accessToken)
            commit("setAccessToken", response.data.accessToken)
            commit("setUser", response.data.summary)
            // this.$router.go('/')
          }
          else {
            commit("setError", 'response.data.data')
          }
          console.log(response.data)
        })
        .catch(function(error) {
          // Handle Errors here.
          // var errorCode = error.code;
          // var errorMessage = error.message;
          console.log(error);
          localStorage.removeItem("userInfo");
          commit("setError", error);
          // ...
        });
    },

    signUserUp({ commit }, data) {
      commit("setLoading", true);
      commit("clearError");
      axios.post('http://212.71.245.25:9191/api/auth/signup', data)
        .then((response) => {
          commit("setLoading", false);

          if (!response.data.success) {
            commit("setError", response.data.data);
          }
          else {
            commit("setSignupSuccess", response.data.success);
          }
          // localStorage.setItem("userInfo", JSON.stringify(newUser));
          commit(response.data);
        });
    },
    signOut({ commit }) {
      localStorage.removeItem("userInfo")
      localStorage.removeItem("accessToken")
      commit("setLogout")
    }
  }
};
