import axios from 'axios'
import authData from './authData';

const headers = {
  Authorization: 'Bearer ' + authData.state.accessToken,
};
const orgId = authData.state.loggedInUser != null ? authData.state.loggedInUser.organizations[0].id : 0

const state = {
  patnerSnackbarMessage: null,
  patnerList: [],
  partner: null
}

const getters = {
  patners: state => state.patnerList,
  partner: state => state.partner,
  patnerSnackbarMessage: state => state.patnerSnackbarMessage,
}

const actions = {
  togglePatnerSnackbarMessage ({ commit }) {
    commit('updateSnackbarMessage', null)
  },
  viewPartner ({ commit }, data) {
    commit('updatePatner', data)
  },
  createPatner ({ commit, dispatch }, data) {
    axios.post('http://212.71.245.25:9191/businessPartner/createBusinessPartner',
      data, {headers: headers}).then((response) => {
      if (response.data.success) {
        dispatch('fetchPatners')
        commit('updatePatner', response.data.data)
      }
      console.log(response.data)
    })
  },
  fetchPatners ({ commit }) {
    axios.get('http://212.71.245.25:9191/businessPartner/getBusinessPartnersByOrganization?orgId=' +orgId, {headers: headers}).then((response) => {
      commit('updatePatnerList', response.data.data)
      console.log(response.data)
    })
  },
}

const mutations = {
  updateSnackbarMessage: (state, payload) => {
    state.patnerSnackbarMessage = payload
  },
  updatePatnerList: (state, payload) => {
    state.patnerList = payload
  },
  updatePatner: (state, payload) => {
    state.partner = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
