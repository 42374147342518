import Vuex from 'vuex'
import Vue from 'vue'
import largeSidebar from './modules/largeSidebar'
import compactSidebar from './modules/compactSidebar'
import chat from './modules/chat'
import themeConfig from './modules/themeConfig'
import authData from './modules/authData'
import systemData from './modules/systemData'
import patnerData from './modules/patnerData'
import taxData from './modules/taxData'
import productData from './modules/productData'
import invoice from './modules/invoice'
import dashboardData from './modules/dashboardData'
import cart from './modules/cart'
import verticalSidebar from './modules/verticalSidebar'
import scrumboard from './modules/scrumboard'

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    largeSidebar,
    compactSidebar,
    chat,
    themeConfig,
    authData,
    systemData,
    patnerData,
    productData,
    dashboardData,
    taxData,
    invoice,
    cart,
    verticalSidebar,
    scrumboard,
  },
})
