import axios from 'axios'
import authData from './authData';

const headers = {
  Authorization: 'Bearer ' + authData.state.accessToken,
};

const state = {
  data: [],
}

const getters = {
  dashboardData: state => state.data,
}

const actions = {
  fetchDashboard ({ commit }) {
    axios.get('http://212.71.245.25:9191/dashboard', {headers: headers}).then((response) => {
      commit('updateData', response.data.data)
      console.log(response.data)
    })
  },
}

const mutations = {
  updateData: (state, payload) => {
    state.data = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
