import axios from 'axios'
import authData from './authData';

const headers = {
  Authorization: 'Bearer ' + authData.state.accessToken,
};
const orgId = authData.state.loggedInUser != null ? authData.state.loggedInUser.organizations[0].id : 0

const state = {
  invoices: [],
  invoice: null,
}

const getters = {
  invoices: state => state.invoices,
  invoice: state => state.invoice,
}

const actions = {
  createInvoice ({ commit }, data) {
    axios.post('http://212.71.245.25:9191/invoice/createInvoice',
      data, {headers: headers}).then((response) => {
      if (response.data.success) {
        
        commit('VIEW_INVOICE', response.data.data)
      }
      console.log(response.data)
    })
  },
  viewInvoice ({ commit }, data) {
    commit('VIEW_INVOICE', data)
  },
  updateInvoice ({ commit }, payload) {
    commit('UPDATE_INVOICE', payload)
  },
  fetchInvoices ({ commit }) {
    axios.get('http://212.71.245.25:9191/invoice/getInvoiceByOrganization?orgId=' +orgId +'&pageNo=1&pageSize=10', {headers: headers}).then((response) => {
      commit('UPDATE_INVOICES', response.data.data)
      console.log(response.data)
    })
  },
}

const mutations = {
  VIEW_INVOICE (state, data) {
    state.invoice = data
    // console.log(data);
  },
  UPDATE_INVOICES (state, data) {
    state.invoices = data
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
