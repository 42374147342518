import axios from 'axios'
import authData from './authData';

const headers = {
  Authorization: 'Bearer ' + authData.state.accessToken,
};
const orgId = authData.state.loggedInUser != null ? authData.state.loggedInUser.organizations[0].id : 0

const state = {
  productSnackbarMessage: null,
  products: [],
}

const getters = {
  products: state => state.products,
  productSnackbarMessage: state => state.productSnackbarMessage,
}

const actions = {
  toggleProductSnackbarMessage ({ commit }) {
    commit('updateSnackbarMessage', null)
  },
  createProduct ({ commit, dispatch }, data) {
    axios.post('http://212.71.245.25:9191/product/createProduct',
      data, {headers: headers}).then((response) => {
      if (response.data.success) {
        commit('updateProductSnackbarMessage', response.data.data)
        dispatch('fetchProducts')
      }
      console.log(response.data)
    })
  },
  updateProduct ({ commit, dispatch }, data) {
    axios.post('http://212.71.245.25:9191/product/updateProduct',
      data, {headers: headers}).then((response) => {
      if (response.data.success) {
        commit('updateProductSnackbarMessage', response.data.data)
        dispatch('fetchProducts')
      }
      console.log(response.data)
    })
  },
  fetchProducts ({ commit }) {
    commit('updateProductList', [])
    axios.get('http://212.71.245.25:9191/product/getProductByOrganization?orgId=' +orgId +'&pageNo=1&pageSize=10', {headers: headers}).then((response) => {
      commit('updateProductList', response.data.data)
      console.log(response.data)
    })
  },
}

const mutations = {
  updateProductSnackbarMessage: (state, payload) => {
    state.productSnackbarMessage = payload
  },
  updateProductList: (state, payload) => {
    state.products = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
