import axios from 'axios'
import authData from './authData';

const headers = {
  Authorization: 'Bearer ' + authData.state.accessToken,
};
const orgId = authData.state.loggedInUser != null ? authData.state.loggedInUser.organizations[0].id : 0

const state = {
  taxRatesSnackbarMessage: null,
  taxRates: [],
}

const getters = {
  taxRates: state => state.taxRates,
  taxRatesSnackbarMessage: state => state.taxRatesSnackbarMessage,
}

const actions = {
  toggleTaxRatesSnackbarMessage ({ commit }) {
    commit('updateTaxRatesSnackbarMessage', null)
  },
  createTaxRate ({ commit, dispatch }, data) {
    axios.post('http://212.71.245.25:9191/tax/createTax',
      data, {headers: headers}).then((response) => {
      if (response.data.success) {
        dispatch('fetchTaxRates')
        commit('updateTaxRatesSnackbarMessage', response.data.data)
      }
      console.log(response.data)
    })
  },
  updateTaxRate ({ commit, dispatch }, data) {
    axios.post('http://212.71.245.25:9191/tax/updateTax',
      data, {headers: headers}).then((response) => {
      if (response.data.success) {
        dispatch('fetchTaxRates')
        commit('updateTaxRatesSnackbarMessage', response.data.data)
      }
      console.log(response.data)
    })
  },
  fetchTaxRates ({ commit }) {
    commit('updateTaxRates', [])
    axios.get('http://212.71.245.25:9191/tax/getTaxByOrganization?orgId=' +orgId+ '&pageNo=1&pageSize=10', {headers: headers}).then((response) => {
      commit('updateTaxRates', response.data.data)
      console.log(response.data)
    })
  },
}

const mutations = {
  updateTaxRatesSnackbarMessage: (state, payload) => {
    state.taxRatesSnackbarMessage = payload
  },
  updateTaxRates: (state, payload) => {
    state.taxRates = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
