/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import authenticate from '@/auth/authenticate'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/views/app/Index'),
      beforeEnter: authenticate,
      redirect: '/dashboard',

      children: [
        {
          path: "/dashboard",
          component: () => import("@/views/app/dashboard/Sales")
        },

        {
          path: "/patners",
          component: () => import("@/views/app/patners/PatnerTable")
        },

        {
          path: "/patner",
          component: () => import("@/views/app/patners/ProfileOne")
        },

        {
          path: "/patner-form",
          component: () => import("@/views/app/patners/PatnerForm")
        },

        {
          path: '/app/sales',
          component: () => import('@/views/app/pages/Pages'),
          children: [
            {
              path: 'invoices',
              component: () => import('@/views/app/sales/InvoiceTable'),
            },
            {
              path: 'create-invoice',
              component: () => import('@/views/app/sales/InvoiceForm'),
            },
            {
              path: 'view-invoice',
              component: () => import('@/views/app/sales/Invoice'),
            },
            {
              path: 'products',
              component: () => import('@/views/app/products/ProductTable'),
            },
            {
              path: 'tax-rates',
              component: () => import('@/views/app/taxrates/TaxRateTable'),
            },
          ],
        },
      ],
    },
    {
      path: '/app/sessions',
      component: () => import('@/views/app/sessions/Sessions'),

      children: [
        {
          path: 'sign-in',
          component: () => import('@/views/app/sessions/SignInTwo'),
        },
        {
          path: 'error',
          component: () => import('@/views/app/sessions/Error'),
        },
        {
          path: 'forgot',
          component: () => import('@/views/app/sessions/Forgot'),
        },
        {
          path: 'lockscreen',
          component: () => import('@/views/app/sessions/Lockscreen'),
        },
        {
          path: 'sign-in-two',
          name: 'login',
          component: () => import('@/views/app/sessions/SignInTwo'),
        },
        {
          path: 'sign-in-three',
          component: () => import('@/views/app/sessions/SignInThree'),
        },
        {
          path: 'sign-in-four',
          component: () => import('@/views/app/sessions/SignInFour'),
        },
        {
          path: 'sign-in-five',
          component: () => import('@/views/app/sessions/SignInFive'),
        },
        {
          path: 'sign-up',
          component: () => import('@/views/app/sessions/SignUpFive'),
        },
        {
          path: 'sign-up-2',
          name: 'signup',
          component: () => import('@/views/app/sessions/SignUpFive'),
        },
        {
          path: 'signup',
          component: () => import('@/views/app/sessions/SignUpFive'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch('changeThemeLoadingState', true)
  }
  next()
})

router.afterEach(() => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById("loading_wrap");
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = "none";
  // }
  // Complete the animation of the route progress bar.
  setTimeout(() => store.dispatch('changeThemeLoadingState', false), 500)
  // NProgress.done();
  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   // console.log("mobile");
  //   store.dispatch("changeSidebarProperties");
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
  //     store.dispatch("changeCompactSidebarProperties");
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  // }
})

export default router
