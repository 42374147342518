import axios from 'axios'
const state = {
  organisationTypes: [],
  businessTypes: [],
  countryList: [],
  currencyList: [],
}

const getters = {
  getOrganisationTypes: state => state.organisationTypes,
  getBusinessTypes: state => state.businessTypes,
  getCountryList: state => state.countryList,
  getCurrencyList: state => state.currencyList,
}

const actions = {
  fetchOrganisationTypes ({ commit }) {
    axios.get('http://212.71.245.25:9191/organizationType/all').then((response) => {
      commit('updateOrganisationTypes', response.data.data)
    })
    // console.log(response)
  },
  fetchBusinessTypes ({ commit }) {
    axios.get('http://212.71.245.25:9191/businessType/all').then((response) => {
      commit('updateBusinessTypes', response.data.data)
    })
    // console.log(response)
  },
  fetchCountries ({ commit }) {
    axios.get('http://212.71.245.25:9191/country/all').then((response) => {
      commit('updateCountryList', response.data.data)
    })
  },
  fetchCurrencies ({ commit }) {
    axios.get('http://212.71.245.25:9191/currency/all').then((response) => {
      commit('updateCurrencyList', response.data.data)
    })
  },
}

const mutations = {
  updateOrganisationTypes: (state, payload) => {
    state.organisationTypes = payload
    // console.log(state.organisationTypes);
  },
  updateBusinessTypes: (state, payload) => {
    state.businessTypes = payload
    // console.log(state.organisationTypes);
  },
  updateCountryList: (state, payload) => {
    state.countryList = payload
  },
  updateCurrencyList: (state, payload) => {
    state.currencyList = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
